:root {
  /* dark shades of primary color*/
  --clr-primary-1: #04122c;
  --clr-primary-2: hsl(185, 84%, 25%);
  --clr-primary-3: hsl(185, 81%, 29%);
  --clr-primary-4: hsl(185, 77%, 34%);
  /* primary/main color */
  --clr-primary-5: hsl(185, 62%, 45%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(185, 57%, 50%);
  --clr-primary-7: hsl(184, 65%, 59%);
  --clr-primary-8: hsl(184, 80%, 74%);
  --clr-primary-9: hsl(185, 94%, 87%);
  --clr-primary-10: hsl(186, 100%, 94%);
  --clr-primary-11: #ffffff;
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: #fafafa;
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --clr-black-1: #373f47;
  --ff-primary: "Poppins", sans-serif;
  --ff-secondary: "Open Sans", sans-serif;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 8px;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1100px;
  --fixed-width: 620px;
  --primary-color: rgba(13, 110, 139, 0.6);
  --overlay-color: rgba(24, 39, 51, 0.85);
  --sidenav-display: block;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}
body {
  font-family: var(--ff-secondary);
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  line-height: 1.5;
  /* font-size: 0.875rem; */
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
img {
  width: 100%;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
textarea,
table,
select,
input {
  letter-spacing: var(--spacing);
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 0.5rem;
  font-family: var(--ff-primary);
  font-weight: 600;
}
.table-row {
  height: 15px !important;
  overflow: hidden;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 0.5rem;
  color: var(--clr-grey-5);
  /* text-align: center; */
  /* font-size: 0.9rem; */
  text-transform: capitalize;
}
@media screen and (min-width: 992px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.2rem;
  }
  p {
    /* font-size: 1rem; */
    text-transform: capitalize;
    letter-spacing: 2px;
  }
  body {
    font-size: 1rem;
    font-family: var(--ff-primary);
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    line-height: 1.5;
    letter-spacing: 2px;
    /* padding: 5px 0; */
    margin: 5px 0;
  }
}
/*  global classes */

.btn {
  text-transform: uppercase;
  background: var(--clr-primary-1);
  color: var(--clr-primary-10);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 400;
  transition: var(--transition);
  font-size: 0.875rem;
  border: 2px solid transparent;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
  width: 140px;

  height: 45px;
}
#dropdown-basic-button {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
}

.btn:hover {
  background: var(--clr-grey-10);
  color: var(--clr-primary-1);
  border: 2px solid var(--clr-primary-1);
}

/* section */
.section {
  padding: 0;
  position: relative;
}

.section-center {
  /* width: 90vw; */
  margin: 0 auto;

  max-width: 1100px;
}

.title {
  text-align: center;
}
.title h3 {
  display: inline-block;
  position: relative;
}

.title h3:after {
  content: "";
  height: 4px;
  width: 50%;
  background-color: var(--clr-primary-1);
  position: absolute;
  bottom: -0.1em;
  left: 50%;
  transform: translate(-50%);
}

.description {
  margin-bottom: 30px;
  text-align: center;
  font-family: var(--ff-primary);
}

.form-control {
  margin: 5px 0;

  border-radius: 6px !important;
  height: 20% !important;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

/* @media screen and (min-width: 1024px) {
  .section-center {
    width: 95vw;
  }
} */
.loading-img {
  width: 20rem;
  height: 20rem;
  display: block;
  margin: 0 auto;
  margin-top: 10rem;
}
main {
  padding-bottom: 1rem;
}

.page-100 {
  min-height: calc(100vh);
  /* padding: 2rem 0; */
  /* border: 2px solid red; */
}
.page {
  min-height: calc(100vh);
}

.side-nav {
  height: 100vh;
  background-color: #04122c;
  position: sticky;
  /* max-width: 100%; */
  z-index: 10;
  top: 0;
  display: var(--sidenav-display);

  /* min-width: 150px; */
}
/* .side-nav ul li:hover {
  background-color: #254175;
  color: #04d1f1 !important;
} */
.hover-items:hover {
  background-color: #254175 !important;
  color: #04d1f1 !important;
}

.login-component {
  border: 1px solid #d7d7d7;
  border-radius: 2%;
  padding: 3%;
  height: 650px !important;
}
.register-component {
  border: 1px solid #d7d7d7;
  border-radius: 2%;
  padding: 3%;
  height: 730px !important;
}
.form-control.form-control-lg::placeholder {
  font-size: 0.8rem;
}

.text-hover-primary {
  color: #4c4c4c;
  font-size: 10px;
}
.text-hover-primary:hover {
  color: #0169fe;
}

.energy {
  background-image: url("localhost:3001/energy_selected.png");
}

.chart-container {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.chart-container svg {
  transform: scale(0.55); /* adjust the scale value as needed */
}

.serach-icon {
  top: 17px;
  left: 300px;
  /* transform: translateY(-50%); */
  position: absolute;
  width: 13px;
  cursor: pointer;
}
.graph-table:hover {
  color: #d6dade !important;
  background-color: #4040f2;
}

.dot-red {
  height: 20px;
  width: 20px;
  background-color: #db0909;
  border-radius: 50%;
  display: inline-block;
}

.dot-yellow {
  height: 20px;
  width: 20px;
  background-color: #fec400;
  border-radius: 50%;
  display: inline-block;
}
.dot-green {
  height: 20px;
  width: 20px;
  background-color: #07c17e;
  border-radius: 50%;
  display: inline-block;
}
